html {
	scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #6a727f;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}
a {
	cursor: pointer;
}
.gulired {
	color: tomato;
} 